import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ImpregnationTypeService } from 'services/impregnation-type-service';

@autoinject
export class ImpregnationTypeList {
  private tableData: Array<Models.ImpregnationType>;
  private activeTableRow: number;
  private ready: boolean = false;

  constructor(
    private impregnationTypeService: ImpregnationTypeService,
    private errorService: ErrorService,
    private router: Router,
    private eventAggregator: EventAggregator
  ) {}

  private activate(params) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  private attached(params) {
    this.eventAggregator.subscribe('impregnationTypeListReset', (value) => {
      this.activeTableRow = null;
      return this.updateView();
    });
    this.updateView();
  }

  private updateView() {
    this.impregnationTypeService
      .getAll('?$orderby=Name&$expand=NavisionProduct,DeltaProduct')
      .then((res) => {
        this.ready = true;
        this.tableData = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoDetailView(id) {
    this.activeTableRow = id;
    this.router.navigateToRoute('impregnation-type-detail', { Id: id });
  }
}
