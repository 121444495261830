import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { ImpregnationTypeService } from "services/impregnation-type-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class impregnationTypeDetail {
  private impregnationtype: Models.ImpregnationType;

  constructor(
    private impregnationTypeService: ImpregnationTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.impregnationTypeService
      .get(params.Id)
      .then(impregnationType => {
        this.impregnationtype = impregnationType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateImpregnationType() {
    this.impregnationTypeService
      .put(this.impregnationtype, this.impregnationtype.Id)
      .then(res => {
        this.eventAggregator.publish("impregnationTypeListReset", 1);
        this.toastService.showSuccess("impregnationtype.updated");
        this.router.navigateToRoute("impregnation-type-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteImpregnationType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.impregnationTypeService
          .delete(this.impregnationtype.Id)
          .then(res => {
            this.eventAggregator.publish("impregnationTypeListReset", 1);
            this.toastService.showSuccess("impregnationtype.deleted");
            this.router.navigateToRoute("impregnation-type-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );
  }
  gotoParentView(){
    this.eventAggregator.publish("impregnationTypeListReset", 1);
    this.router.navigateToRoute("impregnation-type-list");   
  }
}
