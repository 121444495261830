import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { ImpregnationTypeService } from "services/impregnation-type-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class ImpregnationTypeNew {
  private impregnationtype: Models.ImpregnationType = new Models.ImpregnationType();

  constructor(
    private impregnationTypeService: ImpregnationTypeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createImpregnationType() {
    this.impregnationTypeService
      .post(this.impregnationtype)
      .then(res => {
        this.eventAggregator.publish("impregnationTypeListReset", 1);
        this.toastService.showSuccess("impregnationtype.created");
        this.router.navigateToRoute("impregnation-type-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
